<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Marka"
          label-for="id_com_brand"
        >
          <v-select
            id="id_com_brand"
            v-model="filterData.id_com_brand"
            :options="brands"
            label="title"
            :reduce="item => item.id"
            placeholder="Marka"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Fatura Başlangıç"
          label-for="sdate"
        >
          <b-form-datepicker
            id="sdate"
            v-model="filterData.sdate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Fatura Bitiş"
          label-for="edate"
        >
          <b-form-datepicker
            v-model="filterData.edate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          :disabled="!filterData.sdate || !filterData.edate"
          @click="getFilterData"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
        <b-button
          :disabled="loadingReport !== false"
          variant="success"
          class="ml-1"
          :href="downloadExcel"
          target="_blank"
        >
          <FeatherIcon icon="DownloadIcon" />
          Excel Aktar
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormDatepicker, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterData',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormDatepicker,
    BButton,
  },
  props: {
    getFilterData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    downloadExcel() {
      const urlParams = []
      if (this.filterData.sdate) {
        urlParams.push(`sdate=${this.filterData.sdate}`)
      }
      if (this.filterData.edate) {
        urlParams.push(`edate=${this.filterData.edate}`)
      }
      if (this.filterData.id_com_brand) {
        urlParams.push(`id_com_brand=${this.filterData.id_com_brand}`)
      }
      if (this.filterData.id_com_otvrate) {
        this.filterData.id_com_otvrate.forEach(e => {
          urlParams.push(`id_com_otvrate[]=${e}`)
        })
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/sales/sale_invoices/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
    loadingReport() {
      return this.$store.getters['saleInvoices/getLoading']
    },
    filterData() {
      return this.$store.getters['saleInvoices/getFilterData']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
  },
  created() {
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
  },
}
</script>
