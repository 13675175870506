<template>
  <b-card no-body>
    <b-card-text>
      <b-table
        responsive
        :fields="fields"
        :items="dataList"
        striped
        small
      >
        <template #cell(customer_name)="data">
          {{ data.item.customer_name }}
          <div class="font-small-2 text-muted">
            {{ data.item.company_name }}
          </div>
        </template>
        <template #cell(brand)="data">
          {{ data.item.brand }} {{ data.item.model }}
          <div class="font-small-1 text-warning">
            {{ data.item.hardware }}
          </div>
          <div class="font-small-1 text-warning">
            {{ data.item.chasis }}
          </div>
          <div class="font-small-2 text-muted">
            Satış Föy No: {{ data.item.order_number }}
          </div>
        </template>
        <template #cell(invoice_date)="data">
          {{ moment(data.item.invoice_date).format('DD.MM.YYYY') }}
          <div class="font-small-2 text-primary">
            {{ data.item.invoice_no }}
          </div>
        </template>
        <template #cell(oto_bedeli)="data">
          {{ data.item.oto_bedeli | toCurrency }} ₺
        </template>
        <template #cell(otv_tutari)="data">
          {{ data.item.otv_tutari | toCurrency }} ₺
          <div class="text-danger">
            %{{ data.item.otv_orani }}
          </div>
        </template>
        <template #cell(kdv_tutari)="data">
          {{ data.item.kdv_tutari | toCurrency }} ₺
        </template>
        <template #cell(fatura_toplami)="data">
          {{ data.item.fatura_toplami | toCurrency }} ₺
        </template>
        <template #cell(plaka_tutari)="data">
          {{ data.item.plaka_tutari | toCurrency }} ₺
        </template>
        <template #cell(bandrol_tutari)="data">
          {{ data.item.bandrol_tutari | toCurrency }} ₺
        </template>
        <template #cell(arac_satis_bedeli)="data">
          {{ data.item.arac_satis_bedeli | toCurrency }} ₺
        </template>
        <template #cell(aksesuar_bedeli)="data">
          {{ data.item.aksesuar_bedeli | toCurrency }} ₺
        </template>
      </b-table>
    </b-card-text>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCount"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BTable, BCardFooter, BPagination,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BCardFooter,
    BPagination,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'customer_name',
          label: 'MÜŞTERİ',
          thClass: 'font-small-2',
          tdClass: 'font-small-2',
        },
        {
          key: 'brand',
          label: 'MARKA MODEL',
          thClass: 'font-small-2',
          tdClass: 'font-small-2',
        },
        {
          key: 'invoice_date',
          label: 'FATURA',
          thClass: 'font-small-2',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'oto_bedeli',
          label: 'OTO BEDELİ',
          thClass: 'font-small-2 text-right',
          tdClass: 'font-small-2 text-nowrap text-right',
        },
        {
          key: 'otv_tutari',
          label: 'ÖTV TUTARI',
          thClass: 'font-small-2 text-right',
          tdClass: 'font-small-2 text-nowrap text-right',
        },
        {
          key: 'kdv_tutari',
          label: 'KDV TUTARI',
          thClass: 'font-small-2 text-right',
          tdClass: 'font-small-2 text-nowrap text-right',
        },
        {
          key: 'fatura_toplami',
          label: 'Araç Bedeli',
          thClass: 'font-small-2 text-right',
          tdClass: 'font-small-2 text-nowrap text-right',
        },
        {
          key: 'plaka_tutari',
          label: 'PLAKA',
          thClass: 'font-small-2 text-right',
          tdClass: 'font-small-2 text-nowrap text-right',
        },
        {
          key: 'bandrol_tutari',
          label: 'BANDROL',
          thClass: 'font-small-2 text-right',
          tdClass: 'font-small-2 text-nowrap text-right',
        },
        {
          key: 'arac_satis_bedeli',
          label: 'ARAÇ SATIŞ BEDELİ',
          thClass: 'font-small-2 text-right',
          tdClass: 'font-small-2 text-nowrap text-right',
        },
        {
          key: 'aksesuar_bedeli',
          label: 'AKSESUAR BEDELİ',
          thClass: 'font-small-2 text-right',
          tdClass: 'font-small-2 text-nowrap text-right',
        },
      ],
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['saleInvoices/getFilterData']
    },
    dataList() {
      return this.$store.getters['saleInvoices/getReport']
    },
    dataCount() {
      return this.$store.getters['saleInvoices/getDataCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.filterData.start = page
      if (this.filterData.start === page) {
        this.getDataList()
      }
    },
  },
  methods: {
    getDataList() {
      this.$store.dispatch('saleInvoices/report', this.filterData)
    },
  },
}
</script>
